/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { MODULE_NAME } from 'app/core/constant';


// Create an instance of StorageServiceService
const storedData = localStorage.getItem('userPermission');
const localStorageData = storedData ? JSON.parse(storedData) : [];

// Verify the type of `localStorageData`
if (!Array.isArray(localStorageData)) {
    throw new Error('Local storage data is not an array');
}
export const filteredNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        // icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    },
    {
        id   : 'sponsor',
        title: MODULE_NAME.SPONSAR_MANAGEMENT,
        type : 'basic',
        // icon : 'heroicons_outline:chart-pie',
        link : '/sponsor'
    },
    {
        id   : 'organizer',
        title: MODULE_NAME.ORGANIZER_MANAGEMENT,
        type : 'basic',
        // icon : 'heroicons_outline:chart-pie',
        link : '/organizer'
    },
    {
        id   : 'tournamentManagement',
        title: MODULE_NAME.TOURNAMENT_MANAGEMENT,
        type : 'basic',
        // icon : 'heroicons_outline:chart-pie',
        link : '/tournamentManagement'
    },
    {
        id   : 'subAdminManagement',
        title: MODULE_NAME.SUB_ADMIN_MANAGEMENT,
        type : 'basic',
        // icon : 'heroicons_outline:chart-pie',
        link : '/subAdminManagement'
    },
    {
        id   : 'UserManagement',
        title: MODULE_NAME.USER_MANAGEMENT,
        type : 'basic',
        // icon : 'heroicons_outline:chart-pie',
        link : '/UserManagement'
    },
    {
        id   : 'jobListing',
        title: MODULE_NAME.JOB_LISTING,
        type : 'basic',
        // icon : 'heroicons_outline:chart-pie',
        link : '/jobListing'
    },
    {
        id   : 'community',
        title: MODULE_NAME.COMMUNITY_MANAGEMENT,
        type : 'basic',
        // icon : 'heroicons_outline:chart-pie',
        link : '/community'
    },
    {
        id   : 'leaderboard',
        title: MODULE_NAME.LEADERBOARD_MANAGEMENT,
        type : 'basic',
        // icon : 'heroicons_outline:chart-pie',
        link : '/leaderboard'
    },
    {
        id   : 'redeemStore',
        title: MODULE_NAME.REDEEM_MANAGEMENT,
        type : 'basic',
        // icon : 'heroicons_outline:chart-pie',
        link : '/redeemStore'
    },
    {
        id   : 'educationalVideos',
        title: MODULE_NAME.EDUCATIONAL_VIDEO,
        type : 'basic',
        // icon : 'heroicons_outline:chart-pie',
        link : '/educationalVideos'
    },
    {
        id   : 'gameCategory',
        title: MODULE_NAME.GAME_CATEGORY,
        type : 'basic',
        // icon : 'heroicons_outline:chart-pie',
        link : '/gameCategory'
    },
    {
        id   : 'liveStream',
        title: MODULE_NAME.LIVE_STREAM,
        type : 'basic',
        // icon : 'heroicons_outline:chart-pie',
        link : '/liveStream'
    },
    {
        id   : 'comment',
        title: 'Comment',
        type : 'basic',
        // icon : 'heroicons_outline:chart-pie',
        link : '/comment'
    },
    {
        id   : 'cms',
        title: 'CMS Management',
        type : 'basic',
        // icon : 'heroicons_outline:chart-pie',
        link : '/cms'
    },
];

const moduleNamesFromLocalStorage = new Set(
    localStorageData?.map(item => item['module.module_name'])
);

// Step 2: Filter `defaultNavigation` based on these module names
// export const defaultNavigation: FuseNavigationItem[] = filteredNavigation.filter(item =>
//     moduleNamesFromLocalStorage.has(item.title)
// );
export const defaultNavigation: FuseNavigationItem[] = [
    filteredNavigation.find(item => item.id === 'dashboard'), // Always include Dashboard
    ...filteredNavigation.filter(item => moduleNamesFromLocalStorage.has(item.title) && item.id !== 'dashboard') // Filter other items
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
