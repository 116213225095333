import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { ModulePermissionGuard } from './core/auth/guards/module-permission.guard';
import { MODULE_NAME } from './core/constant';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch : 'full', redirectTo: 'tournamentManagement'},

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'tournamentManagement'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes')},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')},
            {path: 'resetPassword/:token', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes')}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes')}
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes')},
        ]
    },

    // Admin routes
    {
        path: '',
        // canActivate: [AuthGuard],
        // canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            // {path: 'example', loadChildren: () => import('app/modules/admin/example/example.routes')},
            {path: 'dashboard', loadChildren: () => import('app/modules/admin/dashboard/dashboard.routes'),
            },
            {path: 'tournamentManagement', loadChildren: () => import('app/modules/admin/tournament-management/tournament.routes'),
            canActivate: [ModulePermissionGuard],
            data: { moduleName: MODULE_NAME.TOURNAMENT_MANAGEMENT }
            },
            {path: 'subAdminManagement', loadChildren: () => import('app/modules/admin/sub-admin/subAdmin.routes'),
                canActivate: [ModulePermissionGuard],
                data: { moduleName: MODULE_NAME.SUB_ADMIN_MANAGEMENT }
            },
            {path: 'UserManagement', loadChildren: () => import('app/modules/admin/user-management/userManagement.routes'),
                canActivate: [ModulePermissionGuard],
                data: { moduleName: MODULE_NAME.USER_MANAGEMENT }
            },
            {path: 'community', loadChildren: () => import('app/modules/admin/community/community.routes'),
                canActivate: [ModulePermissionGuard],
                data: { moduleName: MODULE_NAME.COMMUNITY_MANAGEMENT }
            },
            {path: 'leaderboard', loadChildren: () => import('app/modules/admin/leaderboard/leaderboard.routes'),
                canActivate: [ModulePermissionGuard],
                data: { moduleName: MODULE_NAME.LEADERBOARD_MANAGEMENT }
            },
            {path: 'redeemStore', loadChildren: () => import('app/modules/admin/redeem-store/redeemStore.routes'),
                canActivate: [ModulePermissionGuard],
                data: { moduleName: MODULE_NAME.REDEEM_MANAGEMENT }
            },
            {path: 'jobListing', loadChildren: () => import('app/modules/admin/job-listing/jobListing.routes'),
                canActivate: [ModulePermissionGuard],
                data: { moduleName: MODULE_NAME.JOB_LISTING }
            },
            {path: 'educationalVideos', loadChildren: () => import('app/modules/admin/educational-videos/educationalVideos.routes'),
                canActivate: [ModulePermissionGuard],
                data: { moduleName: MODULE_NAME.EDUCATIONAL_VIDEO }
            },
            {path: 'gameCategory', loadChildren: () => import('app/modules/admin/game-category/gameCategory.routes'),
                canActivate: [ModulePermissionGuard],
                data: { moduleName: MODULE_NAME.GAME_CATEGORY }
            },
            {path: 'liveStream', loadChildren: () => import('app/modules/admin/live-stream/liveStream.routes'),
                canActivate: [ModulePermissionGuard],
                data: { moduleName: MODULE_NAME.LIVE_STREAM }
            },
            {path: 'sponsor', loadChildren: () => import('app/modules/admin/sponsor/sponsor.routes'),
                canActivate: [ModulePermissionGuard],
                data: { moduleName: MODULE_NAME.SPONSAR_MANAGEMENT }
            },
            {path: 'organizer', loadChildren: () => import('app/modules/admin/organizer/organizer.routes'),
                canActivate: [ModulePermissionGuard],
                data: { moduleName: MODULE_NAME.ORGANIZER_MANAGEMENT }
            },
            {path: 'comment', loadChildren: () => import('app/modules/admin/comment/comment.routes'),
                
            },
            {path: 'cms', loadChildren: () => import('app/modules/admin/cms/cms.routes'),
                canActivate: [ModulePermissionGuard],
                data: { moduleName: 'CMS Management' }
            },
        ]
    }
];
