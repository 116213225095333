import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  api_base_url = environment.apiUrl

  constructor(private http: HttpClient) { }

  getUsers(currentPage:any,size:any,searchQuery:any) {
   

    return this.http.get(`${this.api_base_url}/admin/getUserList?limit=${size}&page=${currentPage}&searchQuery=${searchQuery}`);
  }
  versionUserList(currentPage:any,size:any,searchQuery:any) {
   

    return this.http.get(`${this.api_base_url}/admin/versionUserList?limit=${size}&page=${currentPage}&searchQuery=${searchQuery}`);
  }
  getUserListWithoutRole(currentPage:any,size:any,searchQuery:any) {
   

    return this.http.get(`${this.api_base_url}/admin/getUserListWithoutRole?limit=${size}&page=${currentPage}&searchQuery=${searchQuery}`);
  }
  getUserRoleList(currentPage:any,size:any,searchQuery:any) {
   

    return this.http.get(`${this.api_base_url}/admin/getUserRoleList?limit=${size}&page=${currentPage}&searchQuery=${searchQuery}`);
  }
  getInactiveUsers(currentPage:any,size:any,searchQuery:any,status:any) {
   

    return this.http.get(`${this.api_base_url}/admin/getUserList?limit=${size}&page=${currentPage}&searchQuery=${searchQuery}&status=${status}`);
  }
  listRole(){
    return this.http.get(`${this.api_base_url}/admin/getRoleListHierarchy`);
    
  }
  approveUser(data:any){
    return this.http.post(`${this.api_base_url}/admin/approveUser`,data);
    
  }
  rejectUser(data:any){
    return this.http.post(`${this.api_base_url}/admin/rejectUser`,data);
    
  }
  approveUserRole(data:any){
    return this.http.post(`${this.api_base_url}/admin/approveUserRole`,data);
    
  }
  rejectUserRole(data:any){
    return this.http.post(`${this.api_base_url}/admin/rejectUserRole`,data);
    
  }
  createUserRole(data:any){
    return this.http.post(`${this.api_base_url}/admin/createUserRole`,data);
    
  }
  deleteUser(data:any) {
   
    return this.http.put(`${this.api_base_url}/admin/updateUserStatus`,data);
  }
  getUserDetails(data:any) {
   
    return this.http.get(`${this.api_base_url}/admin/getUserDetail?userId=${data.userId}`);
  }
  getUserDetail(data:any,team_page:any,team_limit:any, tournament_page:any,tournament_limit:any) {
   
    return this.http.get(`${this.api_base_url}/admin/getUserDetail?userId=${data.userId}&team_page=${team_page}&team_limit=${team_limit}&tournament_page=${tournament_page}&tournament_limit=${tournament_limit}`);
  }
  addUser(data:any) {
   
    return this.http.post(`${this.api_base_url}/admin/addUser`,data);
  }
  updateUserProfile(data:any) {
   
    return this.http.put(`${this.api_base_url}/admin/updateUserProfile`,data);
  }
  updateUserSecaCredit(data:any) {
   
    return this.http.post(`${this.api_base_url}/admin/updateUserSecaCredit`,data);
  }
  getUserTxnHistoryList(userId:any,currentPage:any,size:any,searchQuery:any) {

    return this.http.get(`${this.api_base_url}/user/getUserTxnHistoryList?userId=${userId}&limit=${size}&page=${currentPage}&searchQuery=${searchQuery}`);
  }
  approveUserSecaCredit(data:any){
    return this.http.post(`${this.api_base_url}/admin/approveUserSecaCredit`,data);
    
  }
  rejectUserSecaCredit(data:any){
    return this.http.post(`${this.api_base_url}/admin/rejectUserSecaCredit`,data);
    
  }
  getVersionUserTxnList(currentPage:any,size:any,searchQuery:any) {
   

    return this.http.get(`${this.api_base_url}/admin/getVersionUserTxnList?limit=${size}&page=${currentPage}&searchQuery=${searchQuery}`);
  }
  // subadmin

  subAdminList(currentPage:any,size:any,searchQuery:any) {
   

    return this.http.get(`${this.api_base_url}/admin/subAdminList?limit=${size}&page=${currentPage}&searchQuery=${searchQuery}`);
  }
  versionSubAdminList(currentPage:any,size:any,searchQuery:any) {
   

    return this.http.get(`${this.api_base_url}/admin/subAdminListForApproval?limit=${size}&page=${currentPage}&searchQuery=${searchQuery}`);
  }
  versionSubAdminListRejected(currentPage:any,size:any,searchQuery:any) {
   

    return this.http.get(`${this.api_base_url}/admin/subAdminListForApproval?limit=${size}&page=${currentPage}&searchQuery=${searchQuery}&status=rejected`);
  }
  getInactivesubAdmin(currentPage:any,size:any,searchQuery:any,status:any) {
   

    return this.http.get(`${this.api_base_url}/admin/subAdminList?limit=${size}&page=${currentPage}&searchQuery=${searchQuery}&status=${status}`);
  }
  addSubAdmin(data:any) {
   
    return this.http.post(`${this.api_base_url}/admin/addSubAdmin`,data);
  }
  approveSubAdmin(data:any){
    return this.http.post(`${this.api_base_url}/admin/approveSubAdmin`,data);
    
  }
  rejectSubAdmin(data:any){
    return this.http.post(`${this.api_base_url}/admin/rejectSubAdmin`,data);
    
  }
  deleteSubAdmin(data:any) {
   
    return this.http.put(`${this.api_base_url}/admin/deleteSubAdmin`,data);
  }
  updateSubAdminStatus(data:any) {
   
    return this.http.post(`${this.api_base_url}/admin/updateSubAdminStatus`,data);
  }
  updateSubAdmin(data:any) {
   
    return this.http.post(`${this.api_base_url}/admin/updateSubAdmin`,data);
  }
  updatePendingSubAdmin(data:any) {
   
    return this.http.post(`${this.api_base_url}/admin/updatePendingSubAdmin`,data);
  }
  userIndex() {
    
    return this.http.get(`${this.api_base_url}/admin/userIndex`);
  }
  changePassword(data:any) {
   
    return this.http.post(`${this.api_base_url}/admin/changePassword`,data);
  }
}
